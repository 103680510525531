.alert-banner-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  height: 100;
  top: 0px;
  z-index: 99999;
  left: 0px;
  background: rgba(000, 000, 000, 0.4);
  .alert-banner {
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    border: 1px solid #7d7d7d;
    width: fit-content;
    min-width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    

    &.success {
      background: #2cc578;
    }
    &.warning {
      background: #e9ec0f;
    }
    &.error {
      background: #ce0707;
      color: #fff;
      h1 {
        color: #fff;
      }
    }
    h1 {
      margin: 0;
      color: #fff;
    }
    .fa {
      float: left;
      color: #fff;
      font-size: 30px !important;
    }
  }
}
.timer-count{
    font-weight: 700;
    font-size: 20px;
    min-width: 30px;
    display: inline-block;
    text-align: center;
}
.custom-alert,
.modal-footer {
  display: flex;
  justify-content: center;
}
