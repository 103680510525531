.burger-menu{
    width: 35px;
    position: fixed;
    right: 0px;
    top: 0px;
    margin: 10px;
    z-index: 99;
    background: #fff;
    border-radius: 100%;
}
.hide-burder{
    transform: translate3d(260px, 0, 0);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}