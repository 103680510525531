.confirm-modal {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9;
  display: flex;

  .confirm-modal-content {
    width: 300px;
    margin: auto;
    background: #fff;
    padding: 20px;
    position: relative;
    border-radius: 10px;
    img {
      width: 20px;
      position: absolute;
      right: 0px;
      top: 0px;
      margin: 5px;
      cursor: pointer;
    }
  }
  .password-input {
    text-align: center;
    border: 1px solid #767676;
    border-radius: 5px;
    font-size: 30px;
    margin-bottom: 5px;
    cursor: no-drop;
  }
  h3 {
    margin: 0px 0px 10px 0px;
    text-align: center;
  }
  .buttons-lines {
    display: flex;
    justify-content: space-between;
    & > div {
      width: 33%;
      border: 1px solid #767676;
      border-radius: 5px;
      margin: 0px 0px 5px;
      padding: 10px;
      font-weight: 700;
      font-size: 30px;
      justify-content: center;
      align-items: center;
      display: flex;
      cursor: pointer;
      &:active {
        background-color: #e3e3e3;
        box-shadow: inset 0px 1px 6px 0px rgba(0, 0, 0, 0.5);
      }
      &:nth-child(2) {
        margin: 0px 5px 5px;
      }
    }
  }
  .action-buttons {
    .ok {
      background: #94bf97;
    }
    .back {
      background-color: #e3e3e3;
    }
  }
  .show-type-container {
    position: relative;
  }
  .show-type {
    width: 20px;
    height: 20px;
    background: url(../../images//show-type.png);
    background-position: 0px 0px;
    position: absolute;
    right: 10px;
    top: 0px;
    margin-top: 5px;
    cursor: pointer;
    &.show {
      background-position: 0px -22px;
    }
  }
  .error-container {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    color: #ff0000;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: -20px;
  }
}
