.hover-edit-field {
    font-size: 18px;
    position: relative;
    padding-left: 10px;
    font-weight: 700;
    display: flex;
    .col-md-4 {
        display: flex;
        justify-content: center;
        align-items: center;
        // height: 33px;
    }

    .fa-edit {
        display: inline;
    }
    .fa-edit {
        display: block !important;
        // position: absolute;
        // left: 0px;
        // top: 0px;
        cursor: pointer;
    }
    .col-md-6,
    .col-md-4 {
        float: left;
        padding-left: 0px;
    }
    .form-control {
        font-size: 18px;
        height: auto;
        padding: 0px 20px 0px 0px;
    }
    b {
        margin-left: 20px;
        display: block;
        width: 100%;
    }
}
