.show-sidebar {
    transform: translate3d(0px, 0, 0);
}
.close-menu {
    width: 30px;
    background: #fff;
    position: absolute;
    z-index: 99;
    border-radius: 100%;
    margin: 20px;
}
