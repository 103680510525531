.payments-container {
  select {
    width: 100%
  }
  .payments-content {
    display: flex;
    background-color: #fff;
    padding: 20px;
    align-items: center;
    .btn-success {
      margin: unset;
      height: fit-content;
    }
  }
  h2 {
    text-align: center;
  }
  .payments-line {
    display: flex;
    justify-content: space-between;
    min-width: 500px;
    overflow-x: scroll;
    div {
      min-width: 50px;
    }
  }
  table {
    width: 100%;
    tfoot td {
      font-weight: 700;
    }
    tr,
    tfoot {
      &.have-payment td:first-child {
        background-color: #9dc1a3;
      }
      td {
        border: 1px solid #747474;
        padding: 5px;
        white-space: nowrap;
        &:first-child,
        &:last-child {
          font-weight: 700;
        }
        &.max-payment {
          background: #ffa3a3;
          font-weight: 700;
        }
        &.min-payment {
          background: #4fa8dd;
          font-weight: 700;
        }
      }
    }
  }

  .selected-table-line {
    background-color: #b1b1b1;
  }
  .form-control {
    width: 100px;
    margin: 10px auto;
    text-align: center;
    font-size: 30px;
  }
  .payments-table-info {
    overflow-x: scroll;
  }
}
.edit-payment-error {
  text-align: center;
  width: 100%;
  font-size: 16px;
  color: #ff0000;
  font-weight: 700;
}
.recharts-surface {
  margin-left: -60px;
  margin-top: 15px;
}
