.container-fluid {
  padding: 0;
}
.date-container .print-content {
  display: inline-block;
}
.date-container > .print-content button {
  margin: 0 !important;
}
.add-payment-error {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  margin-top: 0px;
  color: #c35757;
}
