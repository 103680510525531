.find-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  input {
    text-align: center;
    font-size: 26px;
    font-weight: 700;
  }
}
.edit-service-name {
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
  color: #4e5e5e;
}
