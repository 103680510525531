.date-container {
  // overflow-x: scroll;
  .form-control {
    // margin: 20px;
    // width: 80%;
  }
  .card {
    flex-direction: unset;
    margin: 0px 0px 20px 0px;
  }
  .btn-success {
    margin: 5px auto;
  }
  .card-header {
    width: 100%;
    margin-bottom: 21px;
  }
}
label {
  margin: 20px;
}
.chart {
  position: relative;
}
.recharts-surface {
  width: 100%;
}

.details-modal {
  position: fixed;
  background: rgba(000, 000, 000, 0.3);
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  .details-modals-content {
    width: 50%;
    background: #fff;
    border-radius: 10px;
    border: 3px solid #949494;
    padding: 30px;
    position: relative;
    max-height: 70vh;
    overflow: scroll;
    overflow-x: hidden;
  }
  .close-icon {
    color: #e3e3e3;
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 10px;
    font-weight: 700;
    cursor: pointer;
  }
  .table-responsive .table {
    display: none;
  }
}
