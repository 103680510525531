.table-responsive {
    position: relative;
}

.no-data {
    font-weight: 700;
    text-align: center;
}

.hover-edit-field{
    .fa{
        margin: 3px 5px;
        cursor: pointer;
        font-size: 20px;
    }
}